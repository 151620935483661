import { deleteData, getData, patchData, postData } from './base';

const endPoints = {
  login: '/auth/admin-login',
  profile: '/users/profile',
  users: '/users',
  locations: '/locations',
  presignedUrls: '/locations/presigned-urls',
  settings: '/settings',
  wines: '/wines',
  cigars: '/cigars',
  dressing: 'dressings'
};

const login = (payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.login, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const getProfile = () =>
  new Promise((resolve, reject) => {
    getData(endPoints.profile)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const createUser = (payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.users, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const getUser = (id) =>
  new Promise((resolve, reject) => {
    getData(endPoints.users + '/' + id)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const updateUser = (id, payload) =>
  new Promise((resolve, reject) => {
    patchData(endPoints.users + '/' + id, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const removeUser = (id) =>
  new Promise((resolve, reject) => {
    deleteData(endPoints.users + '/' + id)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const listUsers = (params) => {
  const query = Object.keys(params)
    .filter((key) => params[key] != null)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${endPoints.users}?${query}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getSettings = () =>
  new Promise((resolve, reject) => {
    getData(endPoints.settings)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

// locations
const createLocation = (payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.locations, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const getLocation = (id) =>
  new Promise((resolve, reject) => {
    getData(endPoints.locations + '/' + id)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const updateLocation = (id, payload) =>
  new Promise((resolve, reject) => {
    patchData(endPoints.locations + '/' + id, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const removeLocation = (id) =>
  new Promise((resolve, reject) => {
    deleteData(endPoints.locations + '/' + id)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

const listLocations = (params) => {
  const query = Object.keys(params)
    .filter((key) => params[key] != null)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${endPoints.locations}?${query}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const createPresignedUrls = (payload) =>
  new Promise((resolve, reject) => {
    postData(endPoints.presignedUrls, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const uploadFileToS3 = (urlInfo, file) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(urlInfo.fields).forEach((key) => {
      formData.append(key, urlInfo.fields[key]);
    });
    formData.append('file', file);
    fetch(urlInfo.url, {
      method: 'post',
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return resolve();
        }
        reject(res.status);
      })
      .catch((err) => reject(err));
  });
export const getWineData = () =>
  new Promise((resolve, reject) => {
    getData(`${endPoints.wines}/wineinfo`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export const updateWineData = (payload) =>
  new Promise((resolve, reject) => {
    postData(`${endPoints.wines}/wineinfo`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export const importWines = (payload) =>
  new Promise((resolve, reject) => {
    postData(`${endPoints.wines}/import`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const getCigarData = () =>
  new Promise((resolve, reject) => {
    getData(`${endPoints.cigars}/cigarinfo`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export const updateCigarData = (payload) =>
  new Promise((resolve, reject) => {
    postData(`${endPoints.cigars}/cigarinfo`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export const getDressingData = () =>
  new Promise((resolve, reject) => {
    getData(`${endPoints.dressing}/dressinginfo`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export const updateDressingData = (payload) =>
  new Promise((resolve, reject) => {
    postData(`${endPoints.dressing}/dressinginfo`, payload)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export default {
  login,
  getProfile,
  // user
  createUser,
  getUser,
  updateUser,
  listUsers,
  removeUser,
  // setting
  getSettings,
  // locations
  createLocation,
  getLocation,
  updateLocation,
  removeLocation,
  listLocations,
  //
  createPresignedUrls,
  uploadFileToS3,

  getWineData,
  updateWineData,
  importWines,
  getCigarData,
  updateCigarData,

  getDressingData,
  updateDressingData
};
