import React, { Fragment, Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthGuard from 'src/components/AuthGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import GuestGuard from 'src/components/GuestGuard';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import MainLayout from 'src/layouts/MainLayout';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        exact: true,
        path: '/app/users',
        component: lazy(() => import('src/views/user/UserListView')),
      },
      {
        exact: true,
        path: '/app/users/new',
        component: lazy(() => import('src/views/user/UserDetailsView')),
      },
      {
        exact: true,
        path: '/app/users/:userId',
        component: lazy(() => import('src/views/user/UserDetailsView')),
      },
      {
        exact: true,
        path: '/app/locations',
        component: lazy(() => import('src/views/location/LocationListView')),
      },
      {
        exact: true,
        path: '/app/locations/new',
        component: lazy(() => import('src/views/location/LocationDetailsView')),
      },
      {
        exact: true,
        path: '/app/locations/:locationId',
        component: lazy(() => import('src/views/location/LocationDetailsView')),
      },
      // {
      //   exact: true,
      //   path: '/app/wine_setup',
      //   component: lazy(() => import('src/views/wine')),
      // },
      // {
      //   exact: true,
      //   path: '/app/cigar_setup',
      //   component: lazy(() => import('src/views/cigar')),
      // },
      // {
      //   exact: true,
      //   path: '/app/dressing_setup',
      //   component: lazy(() => import('src/views/dressing')),
      // },

      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView')),
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView')),
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView')),
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/order/OrderListView')),
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/order/OrderDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductListView')),
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView')),
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />,
      },

      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },

  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        component: () => <Redirect to="/login" />,
      },
    ],
  },
];

export default routes;
