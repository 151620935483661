import React, { createContext, useEffect, useReducer } from 'react';

import SplashScreen from 'src/components/SplashScreen';
import api from 'src/services/api';
import axiosInstance from 'src/services/base';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = ({ expiresIn }) => {
  if (!expiresIn) {
    return false;
  }

  return new Date(expiresIn) > new Date();
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem('token', JSON.stringify(token));
  } else {
    localStorage.removeItem('token');
    delete axiosInstance.defaults.headers.common.Authorization;
    return;
  }

  axiosInstance.interceptors.request.use(
    (config) => {
      const authorization = `${token.tokenType} ${token.accessToken}`;
      if (token) {
        config.headers['Authorization'] = authorization;
      }
      config.headers['Content-Type'] = 'application/json';
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    function(error) {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        (originalRequest.url === '/auth/admin-login' ||
          originalRequest.url === '/auth/refresh-token')
      ) {
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const { refreshToken, email } = token;

        return axiosInstance
          .post('/auth/refresh-token', {
            email,
            refreshToken,
          })
          .then((response) => {
            const { data: res } = response;
            localStorage.setItem('token', JSON.stringify(res));
            axiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${res.tokenType} ${res.accessToken}`;
            return axiosInstance(originalRequest);
          });
      }
      Promise.reject(
        (error.response && error.response.data) || 'Something went wrong'
      );
    }
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password) => {
    const response = await api.login({ email, password });
    
    const { token, user } = response;

    setSession(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const token = window.localStorage.getItem('token');
        const parsedToken = JSON.parse(token || '{}');
        if (token && isValidToken(parsedToken)) {
          setSession(parsedToken);

          const user = await api.getProfile();

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
