import React, { createContext, useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Typography } from '@material-ui/core';

const initialGlobalState = {
  loading: false,
  message: '',
  setLoading: () => {},
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    backgroundColor: 'rgba(10,10,10,0.6)',
    flexDirection: 'column',
  },
  message: {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(2.5),
    color: 'white',
  },
}));

const GlobalContext = createContext(initialGlobalState);

export const GlobalProvider = ({ children }) => {
  const [state, setState] = useState({
    loading: false,
    message: '',
  });
  const classes = useStyles();

  const setLoading = (loading, message = '') =>
    setState((prevState) => ({ ...prevState, loading, message }));

  return (
    <GlobalContext.Provider value={{ ...state, setLoading }}>
      {children}
      {state.loading && (
        <div className={classes.container}>
          <CircularProgress size={40} />
          {state.message !== '' && (
            <Typography align="center" className={classes.message}>
              {state.message}
            </Typography>
          )}
        </div>
      )}
    </GlobalContext.Provider>
  );
};

export const GlobalConsumer = GlobalContext.Consumer;

export default GlobalContext;
