export const APP_VERSION = "1.0.0";

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
};

export const USER_STATUS_REGISTERED = "registered";
export const USER_STATUS_CONFIRMED = "confirmed";
export const STATUS_BLOCKED = "blocked";
export const STATUS_VALIDATED = "validated";
export const USER_STATUSES = [
  USER_STATUS_REGISTERED,
  USER_STATUS_CONFIRMED,
  STATUS_BLOCKED,
  STATUS_VALIDATED,
];

const USER_ROLE_ADMIN = "admin";
const USER_ROLE_AGENT = "agent"
export const USER_ROLE_USER = "user";
export const USER_ROLES = [USER_ROLE_ADMIN, USER_ROLE_AGENT, USER_ROLE_USER];
export const ROLES_LIST = [
  { value: USER_ROLE_AGENT, label: 'ambassador' },
  { value: USER_ROLE_USER, label: 'user' }
]
export const LocationPrices = [
  { value: "5", label: "5-10 euros" },
  { value: "10", label: "10-25 euros" },
  { value: "25", label: "25-50 euros" },
  { value: "50", label: "50-100 euros" },
  { value: "100", label: "100-150 euros" },
  { value: "150", label: "150-200 euros" },
  { value: "200", label: "200-350 euros" },
  { value: "350", label: "350-500 euros" },
  { value: "500", label: "500-1000 euros" },
  { value: "1000", label: "+ 1000 euros" }
]
export const HotelId = "5f450432787581669631548f"
export const RestaurantsId = "5f4504327875816696315490"